<template>
  <div class="exp-container bg-nColorBlue flex justify-center items-center">
    <div class="w-80 h-80 md:w-96 md:h-96">
      <div class="outer-container relative" ref="container">
        <canvas
          style="z-index: 2;"
          width="320"
          height="320"
          class="canvas"
          ref="canvas"
          @mousedown="handleMouseDown"
          @mousemove.prevent="handleMouseMove"
          @mouseup="handleMouseUp"
          @touchstart="handleMouseDown"
          @touchmove.prevent="handleMouseMove"
          @touchend="handleMouseUp"
        ></canvas>
        <a :href="link" target="_blank">
          <img
            v-if="showBottomLayer"
            class="secondLayer"
            ref="btmImg"
            :src="revealImage"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    topImage: {
      required: true,
      type: String,
    },
    revealImage: {
      required: true,
      type: String,
    },
    link: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      showBottomLayer: false,
      isDrawing: false,
      lastPoint: [0, 0],
      ctx: "",
      canvas: "",
      container: "",
      brush: "",
      brushURL: process.env.VUE_APP_ASSET_SCRATCH_CARD_BRUSH,
      image: "",
      imageRevealed: false,
    };
  },
  methods: {
    handleMouseDown(e) {
      this.isDrawing = true;
      this.lastPoint = this.getMouse(e, this.canvas);
    },
    handleMouseMove(e) {
      if (!this.isDrawing) return;
      var currentPoint = this.getMouse(e, this.canvas),
        dist = this.distanceBetween(this.lastPoint, currentPoint),
        angle = this.angleBetween(this.lastPoint, currentPoint),
        x,
        y;
      for (var i = 0; i < dist; i++) {
        x = this.lastPoint.x + Math.sin(angle) * i - this.canvas.width / 30;
        y = this.lastPoint.y + Math.cos(angle) * i - this.canvas.height / 30;
        this.ctx.globalCompositeOperation = "destination-out";
        this.ctx.drawImage(
          this.brush,
          x,
          y,
          this.canvas.width / 15,
          this.canvas.width / 15
        );
      }
      this.lastPoint = currentPoint;
    },
    handleMouseUp() {
      this.isDrawing = false;
      this.handlePercentage(this.getFilledInPixels(32));
    },
    distanceBetween(point1, point2) {
      return Math.sqrt(
        Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2)
      );
    },
    angleBetween(point1, point2) {
      return Math.atan2(point2.x - point1.x, point2.y - point1.y);
    },
    getMouse(e, canvas) {
      var offsetX = 0,
        offsetY = 0,
        mx,
        my;
      if (screen.width > 768) {
        offsetX = this.canvas.offsetLeft - canvas.width;
        offsetY = canvas.offsetTop - canvas.height - window.scrollY;
        mx = e.offsetX || e.touches[0].clientX - offsetX;
        my = e.offsetY || e.touches[0].clientY - offsetY;
        this.dbgTouchX = e.offsetX || e.touches[0].clientX;
        this.dbgTouchY = e.offsetY || e.touches[0].clientY;
        this.dbgBcrX = offsetX;
        this.dbgBcrY = offsetY;
        this.dbgMX = mx;
        this.dbgMY = my;
        return { x: mx, y: my };
      } else {
        if (canvas.offsetParent !== undefined) {
          do {
            offsetX += canvas.offsetLeft;
            offsetY += canvas.offsetTop;
          } while ((canvas = canvas.offsetParent));
        }

        mx = e.touches[0].clientX - offsetX;
        my = e.touches[0].clientY - offsetY + window.scrollY;

        return { x: mx, y: my };
      }
    },
    handlePercentage(filledInPixels) {
      filledInPixels = filledInPixels || 0;
      if (filledInPixels > 50) {
        this.canvas.parentNode.removeChild(this.canvas);
      }
    },
    getFilledInPixels(stride) {
      if (!stride || stride < 1) {
        stride = 1;
      }
      var pixels = this.ctx.getImageData(
          0,
          0,
          this.canvas.width,
          this.canvas.height
        ),
        pdata = pixels.data,
        l = pdata.length,
        total = l / stride,
        count = 0;
      // Iterate over all pixels
      for (var i = (count = 0); i < l; i += stride) {
        if (parseInt(pdata[i]) === 0) {
          count++;
        }
      }
      return Math.round((count / total) * 100);
    },
  },
  mounted: function() {
    this.canvas = this.$refs.canvas;
    this.container = this.$refs.container;
    this.canvas.height = 320;
    this.canvas.width = 320;
    this.ctx = this.canvas.getContext("2d", { willReadFrequently: true });
    this.brush = new Image();
    this.image = new Image();
    this.brush.crossOrigin = "anonymous";
    this.image.crossOrigin = "anonymous";
    this.brush.src = this.brushURL;
    this.image.src = this.topImage;
    var vue = this;
    this.image.onload = function() {
      vue.ctx.drawImage(vue.image, 0, 0, 320, 320);
      vue.showBottomLayer = true;
    };
  },
};
</script>

<style scoped>
.debug {
  position: absolute;
  color: tomato;
  z-index: 10;
}
.outer-container {
  position: relative;
  width: 320px;
  height: 320px;
  margin: 0 auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  overflow: hidden;
}
.canvas {
  width: 320px;
  height: 320px;
  top: 0;
  left: 0;
  object-fit: contain;
  position: absolute;
}
.secondLayer {
  width: 320px;
  height: 320px;
}
.responsive {
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.exp-container {
  height: 22rem;
}
@media (min-width: 768px) {
  .exp-container {
    height: 30rem;
  }
}
</style>
