<template>
  <div class="px-3 md:px-12 md:pt-10 mx-auto" v-if="experience">
    <MobileHeader
      :headingText="getHeaderText()"
      routeName="OpenDemosCatalog"
      class="block md:hidden"
    />
    <Heading
      :headingText="getHeaderText()"
      routeName="OpenDemosCatalog"
      class="hidden md:block"
    />
    <div
      class="scratch-card-container w-full h-full md:w-1/2 md:h-1/2 absolute top-28 md:top-40 left-1/2 transform -translate-x-1/2"
      v-if="validSource"
    >
      <ScratchCardExp
        :topImage="openingImage"
        :btmImage="revealImage"
        :url="revealLink"
      />
    </div>
    <Footer class="absolute bottom-0 left-1/2 transform -translate-x-1/2" />
  </div>
</template>

<script>
import ScratchCardExp from "@/components/Experiences/Tools/ScratchCard.vue";
import { apiResource } from "@/common/app.config";
import ApiService from "@/common/api.service";
import obkSlugs from "@/common/slugs";
import MobileHeader from "@/components/Demo/MobileHeader.vue";
import Heading from "@/components/Demo/Heading.vue";
import Footer from "@/components/Demo/CatalogElements/DesktopFooter.vue";

export default {
  name: "ScratchCard",
  props: ["id"],
  components: { ScratchCardExp, MobileHeader, Heading, Footer },
  created() {
    ApiService.get(apiResource.demo.getDetails, {
      slug: obkSlugs.communication.scratchCard,
    })
      .then(({ data }) => {
        this.experience = data.data;
        this.validSource = true;
        this.openingImage = data.data.exp_details.opening_image;
        this.revealImage = data.data.exp_details.reveal_image;
        this.revealLink = data.data.exp_details.reveal_link;
      })
      .catch(() => {});
  },
  data() {
    return {
      experience: null,
      openingImage: null,
      revealImage: null,
      revealLink: null,
      validSource: false,
    };
  },
  methods: {
    getHeaderText() {
      return this.experience.name;
    },
  },
};
</script>

<style scoped></style>
